import React from "react";
import { Box, FormControlLabel, Switch } from "@material-ui/core";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import { Grid } from "semantic-ui-react";

export default function FormCalcularPrecios({ values, edit, ...props }) {
  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <Box pb={1}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                name="is_precio_lista"
                disabled={true}
                checked={values.is_precio_lista}
              />
            }
            label={
              !values.is_precio_lista
                ? "Maneja Precio Costo"
                : " Maneja Precio Mayorista"
            }
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4} lg={4} style={{ marginTop: "-1rem" }}>
        <Box pb={1}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                name="use_discount_charge"
                onChange={() =>
                  props.setFieldValue(
                    "aplica_descuento",
                    !values.aplica_descuento,
                  )
                }
                checked={values.aplica_descuento}
              />
            }
            label={"Agregar descuento/recargo"}
          />
        </Box>
      </Grid>
      <Grid item xs={1} md={1} lg={1} style={{ marginTop: "-1rem" }}>
        <Box pb={1}>
          <TooltipMoreInfoMaterial
            titleTooltip={
              "Activar para aplicar un descuento/recargo específico en este artículo. Mantener desactivado para usar el descuento/recargo de la marca o proveedor."
            }
          />
        </Box>
      </Grid>
    </>
  );
}
