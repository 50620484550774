import React, { useState } from "react";
import ModalReutilizable from "../../../components/ait-reusable/Modal";
import { Box, Grid, Typography } from "@material-ui/core";
import request from "../../../requests/request";
import { errorNotification } from "../../../components/Notifications";
import ButtonAceptar from "../../../components/ait-reusable/Button/ButtonAceptar";
import Alert from "@material-ui/lab/Alert";
import { getPrevisualizacionDePrecios } from "../../../requests/urls";

export default function ModalPrevisualizarArticulo({
  open,
  close,
  articulo_original,
  articulo_editado,
  marcaSelected,
}) {
  const [isLoading, setLoading] = useState(false);
  const [previsualizacion, setPrevisualizacion] = useState(null);

  const obtenerPrevisualizacion = async () => {
    try {
      setLoading(true);
      const req = await request({
        method: "POST",
        url: getPrevisualizacionDePrecios,
        data: {
          precio_costo: articulo_editado.precioCosto
            ? articulo_editado.precioCosto
            : 0,
          precio_lista: articulo_editado.precioLista
            ? articulo_editado.precioLista // Si el proveedor maneja precio de lista y ya contiene el iva, el back le agregará el iva al momento del cálculo.
            : 0,
          descuento_articulo: articulo_editado.aplica_descuento
            ? articulo_editado.descuento
            : null,
          recargo_contado_articulo: articulo_editado.aplica_descuento
            ? articulo_editado.recargo_contado
            : null,
          recargo_lista_articulo:
            articulo_editado.aplica_descuento &&
            !articulo_editado.is_precio_lista
              ? articulo_editado.recargo_lista
              : null,
          proveedor_id: articulo_editado.idProveedor,
          marca_id: marcaSelected && marcaSelected.id,
        },
      });
      let data = req.data;
      setPrevisualizacion(data);

      setLoading(false);
    } catch (error) {
      errorNotification("Error al obtener la previsulizacion de precios");
      console.log(error);
      close();
    }
  };
  React.useEffect(() => {
    obtenerPrevisualizacion();
  }, []);

  const content = previsualizacion ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="info">
          A continuación se muestra una previsualización de los precios finales
          que tendrá el artículo, con los descuentos y recargos aplicados. Estos
          precios serán los que se utilizarán en las transacciones del sistema.
        </Alert>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" align="center">
          Precios cargados
        </Typography>
        <Typography variant="body2" align="center">
          Los precios que ingresaste en el formulario.
        </Typography>
        <Box py={1}>
          <Typography variant="body1" align="center">
            <span style={{ fontWeight: "bold" }}>Precio de costo:</span>
          </Typography>
          <Typography variant="body1" align="center">
            {articulo_editado.precioCosto}
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="body1" align="center">
            <span style={{ fontWeight: "bold" }}>
              Precio de lista (sin IVA):
            </span>
          </Typography>
          <Typography variant="body1" align="center">
            {articulo_editado.precioLista}
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="body1" align="center">
            <span style={{ fontWeight: "bold" }}>Precio de venta:</span>
          </Typography>
          <Typography variant="body1" align="center">
            {articulo_editado.precioVenta}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" align="center">
          Precios finales
        </Typography>
        <Typography variant="body2" align="center">
          Los precios que se usarán en las transacciones del sistema.
        </Typography>
        <Box py={1}>
          <Typography variant="body1" align="center">
            <span style={{ fontWeight: "bold" }}>Precio de costo:</span>
          </Typography>
          <Typography variant="body1" align="center">
            {previsualizacion.costo}
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="body1" align="center">
            <span style={{ fontWeight: "bold" }}>
              Precio de lista (con IVA):
            </span>
          </Typography>
          <Typography variant="body1" align="center">
            {previsualizacion.lista}
          </Typography>
        </Box>
        <Box py={1}>
          <Typography variant="body1" align="center">
            <span style={{ fontWeight: "bold" }}>Precio de venta:</span>
          </Typography>
          <Typography variant="body1" align="center">
            {previsualizacion.venta}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Typography variant="body1">
      No se pudo obtener la previsualización
    </Typography>
  );

  return (
    <ModalReutilizable
      open={open}
      handleClose={close}
      title={"Previsualización de precios"}
      openBackdrop={isLoading}
      content={content}
      actions={<ButtonAceptar click={close} message={"ACEPTAR"} />}
    />
  );
}
