import React from "react";
import { peticiones } from "../../../utils/peticiones";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { listProveedores, listUbicaciones } from "../../../requests/urls";
import EditIcon from "@material-ui/icons/Edit";
import { Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export const initialStateArticulo = () => {
  return {
    aplica_descuento: true,
    idProveedor: "",
    codOriginal: "",
    codAuxiliar: "",
    precioVenta: "",
    precioLista: "",
    precioCosto: "",
    margen_ganancia: "",
    descuento: "",
    codProveedor: "",
    idUbicacion: "",
    stockDisponible: "",
    manejaStock: true,
    descripcionProveedor: "",
    recargo_lista: "",
    recargo_contado: "",
    observaciones: [],
    stock_deseado: "",
    marca: null,
    is_precio_lista: false,
    activo: true /** ---cuando edito--- */,
    rubro: null,
    sub_rubro: null,
    identificador_proveedor: "",
    maneja_unidades: false,
    cantidad_unidades: 1,
    maneja_unidades_inicialmente: false,
  };
};

export const StateEditArticulo = (articulo) => {
  return {
    aplica_descuento: articulo.aplica_descuento,
    idProveedor: articulo.idProveedor,
    codProveedor: articulo.codProveedor,
    codAuxiliar: articulo.codAuxiliar || "",
    codOriginal: articulo.codOriginal || "",
    descripcionProveedor: articulo.descripcionProveedor,
    precioVenta: articulo.precioVenta || "",
    precioLista: articulo.precioLista || "",
    precioCosto: articulo.precioCosto || "",
    margen_ganancia: articulo.margen_ganancia || "",
    idRepuestoProveedor: articulo.idRepuestoProveedor || "",
    descuento: articulo.descuento || "",
    idUbicacion: articulo.idUbicacion,
    recargo_lista: articulo.recargo_lista || "",
    recargo_contado: articulo.recargo_contado || "",
    is_precio_lista: articulo.is_precio_lista,
    stockDisponible: articulo.stockDisponible,
    observaciones: articulo.observaciones || [],
    stock_deseado: articulo.stock_deseado,
    marca: articulo.marca || null,
    idArticulo: articulo.idRepuestoSucursal,
    tipo_descuento: articulo.tipo_descuento,
    rubro: articulo.rubro ? articulo.rubro : null,
    sub_rubro: articulo.sub_rubro ? articulo.sub_rubro : null,
    identificador_proveedor: articulo.identificador_proveedor
      ? articulo.identificador_proveedor
      : null,
    maneja_unidades: articulo.maneja_unidades,
    cantidad_unidades: articulo.cantidad_unidades,
    maneja_unidades_inicialmente: articulo.maneja_unidades,
  };
};

export const ArrayArticulo = (
  values,
  manejaStock,
  ubicacionArticulo,
  obsArticulo,
  marcaSelected,
  addUbicacion,
) => {
  /**
   * Se agrega fix para que si se esta registrando un nuevo articulo se redondeen los decimales a 2 o 4 decimales para enviarlos correctamente a back
   */
  return {
    aplica_descuento: values.aplica_descuento,
    idProveedor: values.idProveedor,
    precioVenta: values.precioVenta ? Number(values.precioVenta).toFixed(2) : 0,
    precioLista: values.precioLista ? Number(values.precioLista).toFixed(2) : 0,
    precioCosto: values.precioCosto ? Number(values.precioCosto).toFixed(2) : 0,
    codOriginal: values.codOriginal,
    codProveedor: values.codProveedor,
    codAuxiliar: values.codAuxiliar,
    descripcionProveedor: values.descripcionProveedor,
    stockDisponible: values.stockDisponible || 0,
    idUbicacion:
      ubicacionArticulo.length > 0 && addUbicacion
        ? ubicacionArticulo[0].idUbicacion.idUbicacion
        : null,
    manejaStock: manejaStock,
    margen_ganancia: values.margen_ganancia
      ? Number(values.margen_ganancia).toFixed(4)
      : 0,
    descuento: values.descuento ? Number(values.descuento).toFixed(4) : 0,
    recargo_lista: values.recargo_lista
      ? Number(values.recargo_lista).toFixed(4)
      : 0,
    recargo_contado: values.recargo_contado
      ? Number(values.recargo_contado).toFixed(4)
      : 0,
    observaciones: obsArticulo || [],
    marca: marcaSelected ? marcaSelected.id : null,
    stock_deseado: values.stock_deseado || null,
    tipo_descuento: values.tipo_descuento,
    rubro: values.rubro ? values.rubro.id : null,
    sub_rubro: values.sub_rubro ? values.sub_rubro.id : null,
    maneja_unidades: values.maneja_unidades,
    cantidad_unidades: values.cantidad_unidades,
  };
};

export const initialStateUbicacion = () => {
  return {
    idDeposito: null,
    idZona: null,
    idEstante: null,
    idUbicacion: null,
  };
};

export const getProveedores = async () => {
  let listProv = [];
  await peticiones("get", listProveedores).then((res) => {
    listProv = res.data.data ? res.data.data : [];
  });
  return listProv;
};

export const getUbicaciones = async (value) => {
  let list = [];
  await peticiones("get", listUbicaciones(value)).then((res) => {
    if (res.data !== "") list = res.data.data;
  });
  return list;
};

export const LabelUbicaciones = ({ ubicacionArticulo, ...props }) => {
  return (
    <Grid>
      <Grid xs={12} item style={{ display: "inline-flex" }}>
        <label className="fontBold pr-2">Ubicación del Artículo:</label>
        <>
          <LocationOnIcon fontSize="small" />
          <label className="fontBold pr-2">{` ${ubicacionArticulo[0].idDeposito.descripcion} - `}</label>
          <label className="fontBold pr-2">{` ${ubicacionArticulo[0].idZona.descripcion} - `}</label>
          <label className="fontBold pr-2">{` ${ubicacionArticulo[0].idEstante.descripcion} - `}</label>
          <label className="fontBold pr-2">{` ${ubicacionArticulo[0].idUbicacion.descripcion} `}</label>
          <EditIcon
            titleAccess="Editar"
            htmlColor="green"
            style={{ cursor: "pointer" }}
            onClick={props.onClick}
          />
          <DeleteIcon
            titleAccess="Eliminar"
            htmlColor="red"
            style={{ cursor: "pointer" }}
            onClick={props.openModalConfirm}
          />
        </>
      </Grid>
    </Grid>
  );
};

export const getProveedorValue = (ref) => {
  return ref.current.values ? ref.current.values.idProveedor : 0;
};
